@font-face {
  font-family: "Inter", sans-serif;
  src: url("./Font/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
  font-family: "Inter";
  margin: 0px;
  padding: 0px;


}



::-webkit-scrollbar {
  background-color: rgba(128, 169, 199, 0.767) !important;
  width: 4px !important;
  height: 10px;
  border-radius: 5px !important;

}

::-webkit-scrollbar-thumb {
  background-color: #043c64e0 !important;
  border-radius: 5px !important;
}



/* Padding */
.zc-p-1 {
  padding: 4px;
}

.zc-p-2 {
  padding: 8px;
}

.zc-p-3 {
  padding: 12px;
}

.zc-p-4 {
  padding: 16px;
}

.zc-p-5 {
  padding: 20px;
}

/* Padding top */
.zc-pt-1 {
  padding-top: 4px;
}

.zc-pt-2 {
  padding-top: 8px;
}

.zc-pt-3 {
  padding-top: 12px;
}

.zc-pt-4 {
  padding-top: 16px;
}

.zc-pt-5 {
  padding-top: 20px;
}

/* Padding Bottom */
.zc-pb-1 {
  padding-bottom: 4px;
}

.zc-pb-2 {
  padding-bottom: 8px;
}

.zc-pb-3 {
  padding-bottom: 12px;
}

.zc-pb-4 {
  padding-bottom: 16px;
}

.zc-pb-5 {
  padding-bottom: 20px;
}

/* Padding Left */
.zc-pl-1 {
  padding-left: 4px;
}

.zc-pl-2 {
  padding-left: 8px;
}

.zc-pl-3 {
  padding-left: 12px;
}

.zc-pl-4 {
  padding-left: 16px;
}

.zc-pl-5 {
  padding-left: 20px;
}

/* Padding Right */
.zc-pr-1 {
  padding-right: 4px;
}

.zc-pr-2 {
  padding-right: 8px;
}

.zc-pr-3 {
  padding-right: 12px;
}

.zc-pr-4 {
  padding-right: 16px;
}

.zc-pr-5 {
  padding-right: 20px;
}

/* Margin */
.zc-m-1 {
  margin: 4px;
}

.zc-m-2 {
  margin: 8px;
}

.zc-m-3 {
  margin: 12px;
}

.zc-m-4 {
  margin: 16px;
}

.zc-m-5 {
  margin: 20px;
}

/* Padding top */
.zc-mt-1 {
  margin-top: 4px;
}

.zc-m-2 {
  margin-top: 8px;
}

.zc-m-3 {
  margin-top: 12px;
}

.zc-m-4 {
  margin-top: 16px;
}

.zc-m-5 {
  margin-top: 20px;
}

/* Margin Bottom */
.zc-mb-1 {
  margin-bottom: 4px;
}

.zc-mb-2 {
  margin-bottom: 8px;
}

.zc-mb-3 {
  margin-bottom: 12px;
}

.zc-mb-4 {
  margin-bottom: 16px;
}

.zc-mb-5 {
  margin-bottom: 20px;
}

/* Margin Left */
.zc-ml-1 {
  margin-left: 4px;
}

.zc-ml-2 {
  margin-left: 8px;
}

.zc-ml-3 {
  margin-left: 12px;
}

.zc-ml-4 {
  margin-left: 16px;
}

.zc-ml-5 {
  margin-left: 20px;
}

/* Margin Right */
.zc-mr-1 {
  margin-right: 4px;
}

.zc-mr-2 {
  margin-right: 8px;
}

.zc-mr-3 {
  margin-right: 12px;
}

.zc-mr-4 {
  margin-right: 16px;
}

.zc-mr-5 {
  margin-right: 20px;
}

.testingArea {
  background-color: rgb(178, 223, 219);
}

.border {
  border: none;
  margin: 0.2rem;
  box-shadow: 0 0px 2px 2px rgba(34, 41, 47, 0.007), 0 1px 3px 2px rgba(67, 73, 78, 0.08)
}

.border-right {
  border: none;
  margin-top: 0px;
  margin-right: 1rem;
  box-shadow: 0 10px 5px 3px rgb(34 41 47 / 5%);
}

.border-bottom {
  border: none;
  -webkit-app-region: drag;
  margin-bottom: 0.5rem;
  box-shadow: 0px 0px 3px 1px #0808084f;
}

.no-drag {
  -webkit-app-region: no-drag;
}


#title-bar {
  -webkit-app-region: drag;
  height: 30px;
  padding: none;
  margin: 0px;
}

#title {
  position: fixed;
  top: 0px;
  left: 6px;
}

#title-bar-btns {
  -webkit-app-region: no-drag;
  position: fixed;
  top: 0px;
  right: 0px;
}

.window-button {
  height: 30px !important;
  line-height: 30px;
  padding: 0 0.5rem;
}

.window-button:hover {
  filter: brightness(85%);
}

.breadcrumb:before {
  color: #343434;
  font-size: 1.2em;
}

.breadcrumb,
.breadcrumb:last-child {
  color: #343434;
  font-size: 0.9em;
}

.breadcrumb:first-child {
  font-weight: bold;
}

input[type="file"] {
  display: none;
}

.zc-custom-file-upload {

  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}


.zc-sale-height {
  max-height: 550px;
  height: 550px;
  min-height: 550px;
  overflow-x: hidden !important;
}

.zc-sale-height-side {
  max-height: 140px;
  min-height: 140px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 10px;
}


.zc-golden-height {
  max-height: 350px;

  overflow-y: auto;
  overflow-x: hidden !important;
}

.zc-golden-height table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;

  color: #37393b;
  margin: 10px 0 0 0;
  text-shadow: #fff 1px -1px 1px;
  text-align: left;
  width: 100%;

  border-collapse: collapse;
}

.zc-golden-height tbody tr td, .zc-golden-height thead tr th {

  padding: 2px 6px;
  font-size: 12px !important;

}


/* For sale add scroll height media query */
@media screen and (max-width:2600px) and (min-width:1400px) {
  .zc-sale-height {
    min-height: 700px;
    max-height: 700px;
    height: 700px;
  }

  .zc-sale-height-side {
    min-height: 409px;
    max-height: 409px;
  }


}

@media screen and (max-width:4600px) and (min-width:2600px) {
  .zc-sale-height {
    min-height: 900px;
    max-height: 900px;
    height: 900px;
  }

  .zc-sale-height-side {
    min-height: 609px;
    max-height: 609px;
  }

}

/* For sale add page css */
.zvcr-row {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.print-table table {
  border-collapse: collapse;
}

.print-table td, th {

  text-align: left;
  padding: 5px;
}

.print-td {
  border: 1px solid #dddddd;
}

.print-hr {
  border-top: 1px solid #d4d4d4;

}

.input_field input {
  font-family: Inter;
  border-color: none;
  height: 42px;
  -webkit-tap-highlight-color: transparent;
  line-height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  min-height: 42px;
  padding-left: 14px;
  padding-right: 31px;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #f1f3f5;
}

.input_field input:focus {
  border-color: #228be6 !important;
  outline: none !important;
}

.SettingBox {
  height: 130px;
  width: 160px;
  margin: auto;
  background: white;
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  border: 1px solid white;
  box-shadow: 0 0px 2px 2px rgba(75, 79, 83, 0.007), 0 1px 3px 2px rgba(151, 154, 156, 0.08);
  transition: all .4s;
  cursor: pointer;
}

.SettingBox:hover {
  border: 1px solid #292929;
  color: #292929;
  box-shadow: '-1px 3px 10px 0 rgb(0 0 0 / 6%)';
}

.editIcon {
  position: absolute;
  top: 6px;
  left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
  transition: all .5s;
}

.SettingBox:hover .editIcon {
  opacity: 1;
  pointer-events: all;
}

.error-boundary {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;

}

.error-boundary, .text {
  text-align: center;
  padding-top: 45px;
  top: 20%;
  width: 100%;
  margin: auto;
}

.link-button {
  text-decoration: none;
  color: rgb(136, 136, 136);
  border: 1px solid rgb(170, 170, 170);
  padding: 1px 5px;
  font-size: 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.link-button:hover {
  color: #043c64;
  border: 1px solid #043c64;
}



/* Table styles */

.tableBorder {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border: 1px solid #dedede;
  color: #37393b;
  margin: 10px 0 0 0;
  text-shadow: #fff 1px -1px 1px;
  text-align: left;
  width: 100%;
}

.tableBorder tbody tr td, .tableBorder thead tr th {
  border-bottom: 1px solid #dedede;
  border-right: 1px solid #dedede;
  padding: 2px 6px;
  font-size: 12px !important;
}

.tableBorder .products td {
  border-bottom: none !important;
}

.invoiceItemHeight {
  max-height: 64vh !important;
  height: 64vh;
  overflow: auto;
}